import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
import IndexConstruction from "./pages/index/indexConstruction";
import Switch from "./component/Switch";
import { WebsiteProvider } from "./WebsiteContext"; // Import the context provider
export default function App() {
  const API = "https://vista.daimooma.com"; // Replace this with your actual API URL
  return (
    <WebsiteProvider API={API}>
      <BrowserRouter>
        <Switch />
        <Routes>
          <Route exact path="/" element={<IndexConstruction />} />
        </Routes>
      </BrowserRouter>
    </WebsiteProvider>
  );
}
