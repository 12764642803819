import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import ab01 from "../../assets/images/construction/ab01.jpg";

import Navbar from "../../component/Navbar/navbar";

import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import CountUp from "react-countup";

import { constructionProject, constructionAbout } from "../../data/dataTwo";

import FooterTwo from "../../component/Footer/footerTwo";
import Bmining from "../../assets/images/Bmining.jpg";
import Vmining from "../../assets/images/Vmining.jpg";
import { WebsiteContext } from "../../WebsiteContext";

export default function IndexConstruction() {
  const [isOpen, setOpen] = useState(false);

  const { websiteData, loading, error } = useContext(WebsiteContext); // Access the website data from context

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="loader">Loading...</div> {/* Add a loading spinner */}
      </div>
    );
  }
  return (
    <div className="font-cairo">
      <Navbar />
      <section
        className="relative md:h-screen py-36 flex items-center bg-center overflow-hidden bg-cover"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${websiteData?.titleImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-white/30 dark:bg-slate-900/60"></div>
        <div className="container relative z-1">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-8 md:col-span-7 md:order-1 order-2">
              <div className="md:me-6">
                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-indigo-800 dark:text-white">
                  {websiteData?.title}
                </h4>
                <p className="text-white dark:text-white/75 text-2xl font-semibold max-w-xl">
                  {websiteData?.description}
                </p>

                <div className="mt-6">
                  <a
                    href="#contact"
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative z-2 transition-all duration-500 ease-in-out sm:-mt-[200px] -mt-[140px] m-0">
              <div className="relative bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md overflow-hidden">
                <div className="grid lg:grid-cols-12 grid-cols-1">
                  <div className="lg:col-span-4 order-1 lg:order-2 bg-indigo-600">
                    <div className="p-[30px] lg:text-start text-center">
                      <span className="text-xl text-white/75">Our proud</span>
                      <p className="text-white/75 max-w-xl mx-auto">
                        {websiteData?.ourProud}
                      </p>
                      <div className="mt-6">
                        <a
                          href="#about"
                          className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md"
                        >
                          About us
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="lg:col-span-8 order-2 lg:order-1">
                    <div className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 p-[30px] gap-[30px]">
                      {constructionAbout.map((item, index) => {
                        let Icons = item.icon;
                        return (
                          <div
                            className="group flex flex-col transition-all duration-500 ease-in-out"
                            key={index}
                          >
                            <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out ml-2 mb-2">
                              <Icons className="size-8" />
                            </div>
                            <div className="flex-1 ms-4">
                              <h4 className="mb-0 text-lg font-semibold text-indigo-900">
                                {item.title}
                              </h4>
                              <p className="text-slate-400 mt-3 text-sm">
                                {websiteData[item.key]}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about"></div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="md:col-span-5">
              <div className="relative">
                <div className="pe-12">
                  <img
                    src={websiteData?.about_im_1}
                    className="rounded-md"
                    alt=""
                  />
                </div>

                <div className="absolute bottom-16 end-0">
                  <img
                    src={websiteData?.about_im_2}
                    className="rounded-md rounded-ee-[30px] shadow-md .size-56"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              videoId="S_CGed6E610"
              onClose={() => setOpen(false)}
            />

            <div className="md:col-span-7">
              <div className="lg:ms-4">
                <span className="bg-indigo-600 inline-block text-white text-xs font-semibold px-2.5 py-0.5 rounded-full h-5">
                  {websiteData?.whoWeAre.title}
                </span>
                <h4 className="mb-6 mt-4 md:text-lg text-2xl md:leading-normal leading-normal font-medium">
                  {websiteData?.whoWeAre.content}
                </h4>

                <div className="mt-6"></div>

                <div className="pt-6 mt-6 border-t dark:border-gray-700">
                  <div className="relative grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="counter-box">
                      <h1 className="text-4xl font-bold mb-2 text-indigo-900">
                        <CountUp
                          className="counter-value text-indigo-900"
                          start={0}
                          end={websiteData?.whoWeAre.number1}
                        />
                        +
                      </h1>
                      <h5 className="counter-head text-lg font-medium">
                        {websiteData?.whoWeAre.phraseNumber1}
                      </h5>
                    </div>

                    <div className="counter-box">
                      <h1 className="text-4xl font-bold mb-2 text-indigo-900">
                        <CountUp
                          className="counter-value text-indigo-900"
                          start={0}
                          end={websiteData?.whoWeAre.number2}
                        />
                        +
                      </h1>
                      <h5 className="counter-head text-lg font-medium">
                        {websiteData?.whoWeAre.phraseNumber2}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="services"></div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-indigo-900">
              {websiteData?.servicesTitle}
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              {websiteData?.servicesDescription}
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {websiteData?.services.map((item, index) => {
              return (
                <div
                  className="group relative block overflow-hidden rounded-md transition-all duration-500"
                  key={index}
                >
                  <Link to="#">
                    <img
                      src={item.image}
                      className="rounded-md shadow dark:shadow-gray-800"
                      alt=""
                    />
                  </Link>
                  <div className="content pt-3 text-center">
                    <h5 className="mb-1">
                      <p className="hover:text-indigo-600 transition-all duration-500 font-semibold text-base text-indigo-900">
                        {item.title}
                      </p>
                    </h5>
                    <h6 className="text-slate-400">{item.feature}</h6>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <div id="contact"></div>
      <FooterTwo />
    </div>
  );
}
